<template>
    <div style="padding-bottom: 10vh;">
        <div class="page-table2-buttons-container">
            <StandardInput class="contract-status-input" :list="status" :action="setSelectedStatus" :type="'select'" title="Status" />
        </div>
        
        <div class="page-table-header">
            <div class="page-table-header-text id-width">ID</div>
            <div class="page-table-header-text store-width">
                <div>Responsavel</div>
            </div>
            <div class="page-table-header-text name-width">Email</div>
            <div class="page-table-header-text pendencie-width">Valor</div>
            <div class="page-table-header-text date-width">Data</div>
            <div class="page-table-header-text status-width">Status</div>
            <div class="page-table-header-text actions-width">Ações</div>
        </div>
        <div v-for="(contract, idx) in bills" :key="idx" :class="getSpecialBackground(idx)" class="page-table-row-contracts">
            <div class="page-table-desc-column id-width">
                <div class="table1-mobile-header">ID</div>
                {{ contract.id }}
            </div>
            <div class="page-table-desc-column store-width">
                <div class="table1-mobile-header">Responsavel</div>
                <div>
                    <div style="text-transform: uppercase;">{{ contract.customer.name }}</div>
                </div>

            </div>
            <div class="page-table-desc-column name-width">
                <div class="table1-mobile-header">Email</div>
                {{ contract.customer.email }}
            </div>
            <div class="page-table-desc-column pendencie-width">
                <div class="table1-mobile-header">Valor</div>
                <div class="contract-pendencies-icon">
                    {{ getContractValue(contract) }}
                </div>
            </div>
            <div class="page-table-desc-column date-width">
                <div class="table1-mobile-header">Data</div>
                {{ formatDate(contract.created_at) }}
            </div>
            <div class="page-table-desc-column status-width">
                <div class="table1-mobile-header">Status</div>
                <div class="contract-status" :class="getStatusClass(contract.status)">{{ translateStatus(contract.status) }}
                </div>
            </div>
            <div class="page-table-desc-column actions-width">
                <div class="table1-mobile-header">Ações</div>
                <!-- <span class="material-icons-outlined" v-on:click="deleteContract(contract)"
            style="color: lightcoral; cursor: pointer;" v-if="(contract.cnt_contas_atrasada > 0)">delete</span> -->
                <!-- <span class="material-icons-outlined" v-else>edit</span> -->
            </div>
        </div>
        <div class="page-table2-pagination-container">
            <div class="page-table2-pagination-button page-table2-pagination-button-hover" v-on:click="pageBefore()">Anterior</div>
            <div class="page-table2-pagination-button">{{this.page}}</div>
            <div class="page-table2-pagination-button page-table2-pagination-button-hover" v-on:click="pageNext()">Próximo</div>
        </div>
        <cc-loader-full v-if="is_loading"/>
    </div>
</template>

<script>
import contractService from '../../services/v3/contractsService';
import StandardInput from '@/components/ui/inputs/StandardInputV2.vue'

export default {
    data() {
        return {
            contractService: new contractService(),
            bills: [],
            status: [{ text: 'TODOS', value: undefined }, { text: 'Pendente', value: "pending" }, { text: 'Pago', value: "paid" }, { text: 'Cancelado', value: "canceled" }],
            selectedStatus: "pending",
            is_loading: false,
            page: 1
        }
    },
    components: {
        StandardInput
    },
    created() {
        this.loadBills()
    },
    methods: {
        pageBefore () {
            if (this.page > 1) {
                this.page--
                this.loadBills()
            }
        },
        pageNext () {
            this.page++
            this.loadBills()
        },
        loadBills() {
            this.is_loading = true
            this.contractService.allContracts2(this.selectedStatus, this.page).then(resp => {
                if (resp.data.data.length < 1 && this.page > 1) {
                    this.page--
                    this.is_loading = false 
                }
                else {
                   this.bills = resp.data.data
                    this.is_loading = false 
                }
                
            })
        },
        getSpecialBackground(idx) { if (idx % 2 != 0) return 'page-table-line-special' },
        formatDate(date) {
            if (date) {
                date = date.split('T')[0]
                return date.split('-')[2] + '/' + date.split('-')[1] + '/' + date.split('-')[0]
            }
            else return '--'
        },
        translateStatus(status) {
            if (status == 'paid') return 'Pago'
            else if (status == 'canceled') return 'Cancelado'
            else if (status == 'pending') return 'Pendente'
            else return status
        },
        getStatusClass(status) {
            if (status == 'paid') return 'contract-a'
            else if (status == 'canceled') return 'contract-i'
            else return status
        },
        setSelectedStatus(status) { 
            this.selectedStatus = status
            this.loadBills()
        },
        getContractValue (contract) {
            return 'R$ ' + Number(contract.amount).toFixed(2)
        }

    }
}
</script>

<style scoped>
.contract-buttons {
    display: flex;
    margin-top: 2.5vh;
    align-items: center;
}

.contract-document-input {
    width: 25%;
}

.contract-status-input {
    width: 25%;
    margin-top: 5vh;
}

.contract-clean-input {
    margin-left: auto;
}

.page-table-header {
    background: #FFFEFC;
    border: 0.5px solid #E5E5E5;
    font-weight: 400;
    color: #605F5F;
    display: flex;
    align-items: center;
    padding: 1em 0em;
    margin-top: 5vh;
}

.page-table-header-text {
    padding-left: 10px;
    font-weight: 600;
    font-size: 1.19em;
    color: #505050;
}

.page-table-row-contracts {
    display: flex;
    align-items: center;
    font-weight: 300;
    font-size: 1.0em;
    color: #605F5F;
    padding: 1vh 0;
}

.page-table-desc-column {
    padding: 0.5em 0.7em;
}
.page-table2-pagination-container{
    margin-top: 5vh;
    display: flex;
    justify-content: center;
    gap: 2vw;
}
.page-table2-pagination-button{
    color: #ff7110;
    border: 1px solid #ff7110;
    width: 10vw;
    text-align: center;
    border-radius: 8px;
    padding: 1vh 0;
    font-size: 1.3em;
    cursor: pointer;
}
.page-table2-pagination-button-hover:hover{
    background-color: #ff7110;
    color: white;
}

.table1-mobile-header {
    display: none;
}

.page-table-line-special {
    background: #F7F7F7;
}
.page-table2-buttons-container{
    display: flex;
    gap: 3vw;
    align-items: end;
}

.id-width {
    width: 10%;
}

.store-width {
    flex: 1;
}

.name-width {
    width: 25%;
}

.date-width {
    width: 12%;
}

.actions-width {
    width: 10%;
}

.pendencie-width {
    width: 10%;
}

.status-width {
    width: 12%;
}

.contract-status {
    border: lightgray 1px solid;
    border-radius: 8px;
    width: fit-content;
    padding: 0 10%;
}

.contract-a {
    background-color: lightgreen;
    color: white;
}

.contract-i {
    color: white;
    background-color: lightcoral;
}

.contract-pagination {
    margin-top: 2vh;
    margin-bottom: 7vh;
}



.contract-check-container {
    display: flex;
    align-items: center;
    margin-left: 5%;
    gap: 4%;
    cursor: pointer;
}

.contract-check-title {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #6e6e6e;
}

.contract-checked-icon {
    color: #ff7110;
    font-size: 35px;
}

.contract-unchecked-icon {
    color: lightgray;
    font-size: 35px;
}


@media only screen and (max-width: 900px) {
    .page-table2-pagination-button{width: 20vw;}
    .page-table-header {
        display: none;
    }

    .table1-mobile-header {
        display: flex;
        font-weight: 700;
        font-size: 1.1em;
    }

    .page-table-row-contracts {
        flex-direction: column;
        margin-top: 6vh;
        font-size: 1.5em;
    }

    .id-width {
        width: 100%;
    }

    .store-width {
        flex: unset;
        width: 100%;
    }

    .name-width {
        width: 100%;
    }

    .date-width {
        width: 100%;
    }

    .actions-width {
        width: 100%;
    }

    .pendencie-width {
        width: 100%;
    }

    .status-width {
        width: 100%;
    }

    .page-table-desc-column {
        display: flex;
        justify-content: space-between;
        text-align: end;
    }
}

@media only screen and (max-width: 700px) {
    .contract-buttons {
        flex-direction: column;
        align-items: flex-start;
    }

    .contract-document-input {
        width: 100%;
        margin-bottom: 2vh;
    }

    .contract-status-input {
        margin-left: 0;
        margin-bottom: 2vh;
        width: 100%;
    }

    .contract-check-container {
        width: 100%;
        margin-left: 0;
    }

    .contract-clean-input {
        margin-left: 0;
        margin-top: 3vh;
        width: 50%;
    }
}

@media only screen and (max-width: 500px) {
    .page-table2-pagination-button{width: 25vw;}
    .contract-page {
        font-size: 3vw;
        margin-right: 5vw;
    }

    .contract-clean-input {
        width: 100%;
    }
}
</style>